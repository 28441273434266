import {useApi} from "components/token";
import {Timetable} from "components/timetable";
import {Loading} from "components/ui";
import {
	Page,
	PageNav, PageNavBreadcrumbs, PageNavCrumb, PageNavCrumbLink
} from "components/layout";

export default function TimetableBellTimes() {
    let [bellTimes] = useApi("GET", "/timetable/bell-times");
    return (
        <Page title="Timetable">
			<PageNav>
				<PageNavBreadcrumbs>
					<PageNavCrumb title="Timetable"/>
					<PageNavCrumbLink title="Bell times" href="/timetable/bell-times"/>
				</PageNavBreadcrumbs>
			</PageNav>
            <Loading condition={bellTimes} className="w-1/5 max-w-16 mx-auto">
                <Timetable bellTimes={bellTimes ?? []} forEach={bellTimes}>
                    {bt => <TimetableBellTime startTime={bt["StartTime"]} endTime={bt["EndTime"]}/>}
                </Timetable>
            </Loading>
        </Page>
    );
}

function TimetableBellTime({startTime, endTime}) {
    return (
        <div className="p-1 bg-slate-400 rounded shadow grid grid-flow-col gap-1 text-center text-xs text-slate-600 font-medium">
            <span className="p-2 bg-white rounded">{startTime} <span className="text-slate-400 font-normal">-</span> {endTime}</span>
        </div>
    );
}
